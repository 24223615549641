.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .teams-text {
      margin-bottom: 6rem;
    }

    .teams-header {
      margin-top: 0;
    }

    .link-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .button {
        max-width: 13rem;
      }
    }
  }
}
