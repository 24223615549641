/* scss does not transpile @keyframes properly so we need to use a regular .css file */
@keyframes bounce {
    0% {
      top: 0;
    }
  
    50% {
      top: -20px;
    }
  
    100% {
      top: 0;
    }
  }
  
  @keyframes fade-in-left {
    0% {
      left: -31.25rem;
      opacity: 0;
    }
  
    100% {
      left: 0;
      opacity: 1;
    }
  }
  
  @keyframes fade-in-right {
    0% {
      opacity: 0;
      right: -500px;
    }
  
    100% {
      opacity: 1;
      right: 0px;
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes blur {
    0% {
      filter: blur(bpk-spacing-md());
    }
    100% {
      filter: blur(0);
    }
  }
  
  @keyframes greyscale {
    0% {
      filter: grayscale(1);
    }
    100% {
      filter: grayscale(0)
    }
  }

  @keyframes fade-in-top {
    0% {
      top: -50px;
      opacity: 0;
    }
  
    100% {
      top: 0;
      opacity: 1;
    }
  }
