@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

$nav-width: 14rem;
$transition: 0.5s ease-in-out;

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .main-header {
      width: 100%;
      margin-top: 1rem;
      text-align: center;
    }

    .header-content {
      width: 100%;
    }

    .header-nav {
      display: none;
    }

    .skyscanner-logo {
      width: 10.5rem;
      height: tokens.bpk-spacing-lg();
      fill: tokens.$bpk-color-sky-blue;
    }

    .side-nav {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
      width: $nav-width;
      height: 100%;
      transform: translateX(-$nav-width);
      background-color: tokens.$bpk-color-white;
      overflow-y: auto;
      backface-visibility: hidden;
    }

    .side-nav.should-transition {
      transition: transform $transition;
    }

    .side-nav:target,
    .side-nav.expanded {
      transform: translate(0, 0);
    }

    .side-nav:target + .backdrop,
    .side-nav.expanded + .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      visibility: visible;
      z-index: 10;
      width: 100%;
      height: 100%;
      transition:
        opacity $transition,
        visibility $transition;
      border: none;
      background: #000;
      background: rgba(0, 0, 0, 0.5);
      cursor: default;
      opacity: 1;
    }

    .menu-toggle {
      position: absolute;
      left: 1rem;
      z-index: 12;
      padding: 0;
      border: 0;
      background: none;
      user-select: none;

      &:active {
        background-color: transparent;
      }

      svg {
        fill: tokens.$bpk-color-sky-blue;
      }
    }

    .menu-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1.2rem tokens.bpk-spacing-md();
      border: none;
      background-color: transparent;
    }

    .main-content {
      transform: none;
      transition: transform $transition;
      backface-visibility: hidden;
    }

    .side-nav:target ~ .main-content,
    .side-nav.expanded ~ .main-content {
      transform: translateX($nav-width);
    }

    .side-nav-links {
      margin: 2.5rem 0 0 1rem;
      padding: 0;

      > li {
        color: tokens.$bpk-color-sky-blue;
        list-style: none;
      }

      a {
        display: inline-block;
        padding: tokens.bpk-spacing-md() 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .sub-menu {
      z-index: 11;
      margin: 0;
      padding: 0;
      transform: scale(1, 0);
      transition: transform 0.2s ease-in-out;
      background: tokens.$bpk-color-white;
      list-style-type: none;
      transform-origin: 0 0;

      li {
        white-space: nowrap;

        &::before {
          content: '-';
          margin-right: tokens.bpk-spacing-md();
        }
      }

      .sub-menu-link {
        display: inline-block;
      }
    }

    .sub-menu:target,
    .sub-menu[aria-expanded='true'] {
      transform: scale(1, 1);
    }

    .flare-bar__container {
      width: 100%;
      height: tokens.bpk-spacing-lg();
      align-self: flex-end;
      text-align: left;
      overflow: hidden;
    }

    .flare-bar__curve {
      position: absolute;
      bottom: -(tokens.$bpk-border-size-sm);
      width: 700rem;
      height: tokens.bpk-spacing-lg();
      margin-left: 50%;
      transform: translateX(-50%);
      fill: tokens.$bpk-color-white;
    }

    @supports (position: fixed) {
      .side-nav,
      .side-nav:target + .backdrop,
      .side-nav.expanded + .backdrop {
        position: fixed;
      }
    }

    /* stylelint-disable-next-line order/order */
    @media #{tokens.$bpk-breakpoint-query-above-mobile} {
      .logo {
        display: block;
        padding: 0;
      }

      .skyscanner-logo {
        width: 14.5rem;
        height: 2.2rem;
        margin: 0 tokens.bpk-spacing-md() tokens.bpk-spacing-md();
      }

      .menu-toggle {
        top: 1.4rem;
      }
    }

    @media screen and (min-width: tokens.$bpk-breakpoint-desktop) {
      .side-nav,
      .menu-toggle,
      .side-nav .menu-close {
        display: none;
      }

      .header-nav {
        display: block;
        z-index: 15;
      }

      .main-header {
        display: flex;
        margin-top: 2rem;
        margin-bottom: 1rem;
        justify-content: space-between;
      }

      .header-nav-links {
        display: flex;
        margin: 0 0 0 1.2rem;
        padding: 0;
        color: tokens.$bpk-color-sky-blue;
        list-style: none;

        li {
          position: relative;
        }

        a {
          display: inline-block;
          margin: 0 1.2rem;
          padding: 0.35rem 0.35rem 0.1rem;
          border-bottom: 0.1875rem solid transparent;

          &:hover,
          &.active {
            border-bottom: 0.1875rem solid tokens.$bpk-color-sky-blue;
          }
        }
      }

      .sub-menu {
        position: absolute;
        left: 0.05rem;
        display: block;
        visibility: visible;
        transform: scale(1, 0);
        transition: all 0.2s ease-in-out;
        background-color: tokens.$bpk-color-white;
        list-style: none;
        transform-origin: 0 0;

        li {
          &::before {
            content: '';
            margin: 0;
          }
        }

        a.sub-menu-link {
          display: block;
          min-width: 9rem;
          margin: 1.2rem;
          margin-top: tokens.bpk-spacing-md();
          padding: 0.35rem 0.35rem 0.1rem;
          color: tokens.$bpk-color-sky-blue;
          text-align: left;
        }
      }

      li:hover > .sub-menu,
      .header-nav .sub-menu:hover {
        transform: scale(1, 1);
        opacity: 1;
      }
    }

    .banner-toggle {
      width: 100%;
      height: 100%;
      padding: 1rem;
      border: none;
      background-color: tokens.$bpk-color-panjin;
      text-align: left;
      cursor: pointer;

      @include typography.bpk-heading-5;
    }

    .banner-toggle__text {
      max-width: 80rem;
      margin: 0 auto;
      color: tokens.$bpk-color-white;
      text-decoration: underline;
    }

    /* stylelint-disable-next-line order/order */
    @media #{tokens.$bpk-breakpoint-query-above-mobile} {
      .banner-toggle {
        @include typography.bpk-heading-4;
      }
    }

    .banner-content {
      margin-bottom: 2.25rem;
      padding: 1rem;

      @media #{tokens.$bpk-breakpoint-query-above-mobile} {
        padding-bottom: 0;
      }
    }

    .banner-content__text {
      max-width: 80rem;
      margin: 0 auto;
    }

    .banner-content[aria-expanded='true'] {
      display: inherit;
    }

    .banner hr {
      width: 100%;
      height: 0.125rem;
      margin: 0;
      border: 0;
      background-color: tokens.$bpk-marcomms-grey-40;
    }
  }
}
