@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

$prep-pack-paragraph-large-font-size: 1.75rem; // prep pack designed with text between backpack sizes

.modal {
  h2 {
    font-family: tokens.$bpk-font-family-base;

    @include typography.bpk-heading-2;

    @include breakpoints.bpk-breakpoint-above-mobile {
      @include typography.bpk-hero-5;
    }
  }

  h3 {
    margin-bottom: tokens.bpk-spacing-lg();
    font-family: tokens.$bpk-font-family-base;

    @include typography.bpk-heading-3;

    @include breakpoints.bpk-breakpoint-above-mobile {
      margin-bottom: tokens.bpk-spacing-xxl();

      @include typography.bpk-heading-1;
    }
  }

  h4 {
    margin-bottom: tokens.bpk-spacing-lg();
    color: tokens.$bpk-color-sky-blue;
    font-family: tokens.$bpk-font-family-base;

    @include typography.bpk-heading-3;

    @include breakpoints.bpk-breakpoint-above-mobile {
      margin-bottom: tokens.bpk-spacing-xl();

      @include typography.bpk-heading-2;
    }
  }

  p {
    margin-bottom: tokens.bpk-spacing-base();
  }

  p,
  a,
  ul,
  li {
    font-size: tokens.$bpk-font-size-sm;
    line-height: tokens.$bpk-line-height-sm;

    @include breakpoints.bpk-breakpoint-above-mobile {
      font-size: tokens.$bpk-font-size-lg;
      line-height: tokens.$bpk-line-height-lg;
    }

    @include breakpoints.bpk-breakpoint-above-tablet {
      font-size: $prep-pack-paragraph-large-font-size;
      line-height: tokens.$bpk-line-height-xxl;
    }
  }

  a {
    color: tokens.$bpk-color-sky-blue;
    text-decoration: underline;
  }

  hr {
    margin: tokens.bpk-spacing-base() 0;
    padding: 0;
    border: 0;
    border-top: tokens.$bpk-border-size-sm solid tokens.$bpk-marcomms-dark-sky;
  }

  .interview-note {
    text-align: left;

    @include typography.bpk-caption;

    @include breakpoints.bpk-breakpoint-above-mobile {
      @include typography.bpk-body-longform;
    }
  }
}
