.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .value-container {
      padding-bottom: 4rem;
    }

    .value-icon {
      float: left;
      width: 7rem;
      height: 7rem;
      margin-right: 3rem;
      margin-bottom: 1rem;
    }
  }
}
