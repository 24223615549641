@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .page-sub-title {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0.3rem;
      // stylelint-disable-next-line scale-unlimited/declaration-strict-value
      font-size: 2.5rem;
      font-weight: 400;
      // stylelint-disable-next-line scale-unlimited/declaration-strict-value
      line-height: 3rem;

      @include breakpoints.bpk-breakpoint-tablet {
        // stylelint-disable-next-line scale-unlimited/declaration-strict-value
        font-size: 1.25rem;
        // stylelint-disable-next-line scale-unlimited/declaration-strict-value
        line-height: 1.75rem;
      }
    }

    .teams-engineering-paragraph {
      // stylelint-disable-next-line scale-unlimited/declaration-strict-value
      font-size: 1.25rem;
      font-weight: 400;
      // stylelint-disable-next-line scale-unlimited/declaration-strict-value
      line-height: 1.75rem;
    }
  }
}
