@mixin bounce-animation {
  position: relative;
  top: 0;
  animation-delay: 0.5s;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: ease-in-out;
}

@mixin common-fade-animation {
  position: relative;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@mixin fade-in-animation {
  animation-delay: 0.5s;
  animation-name: fade-in;

  @include common-fade-animation;
}

@mixin fade-in-left-animation {
  left: -31.25rem;
  animation-delay: 0.5s;
  animation-name: fade-in-left;

  @include common-fade-animation;
}

@mixin fade-in-right-animation {
  right: -31.25rem;
  animation-delay: 0.5s;
  animation-name: fade-in-right;

  @include common-fade-animation;
}

@mixin blur-animation {
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: blur;
  animation-timing-function: ease-in-out;
}

@mixin greyscale-animation {
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: greyscale;
  animation-timing-function: ease-in-out;
}

@mixin fade-in-left-animation-delay($delay) {
  left: -31.25rem;
  animation-delay: #{$delay}s;
  animation-name: fade-in-left;

  @include common-fade-animation;
}

@mixin fade-in-animation-delay($delay) {
  animation-delay: #{$delay}s;
  animation-name: fade-in;

  @include common-fade-animation;
}

@mixin fade-in-top-animation-delay($delay) {
  top: -15rem;
  animation-delay: #{$delay}s;
  animation-name: fade-in-top;

  @include common-fade-animation;
}
