@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/cards';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/icons';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .cards-list {
      margin: 0 auto;
      padding-left: 0;
      justify-content: center;
      list-style-type: none;

      a {
        display: block;
        margin: tokens.$bpk-one-pixel-rem * 18 0;
        text-decoration: none;

        @include breakpoints.bpk-breakpoint-tablet {
          margin: tokens.$bpk-one-pixel-rem * 12 0;
        }

        @include breakpoints.bpk-breakpoint-mobile {
          margin: 0;
        }
      }

      img {
        max-width: 16.25rem;
        height: auto;
      }

      &--early-careers {
        @include breakpoints.bpk-breakpoint-above-tablet {
          justify-content: left;
        }

        img {
          float: right;
          width: 6.7rem;
          margin: 1.9rem;
          margin-right: 1.6rem;
        }
      }
    }

    .card-container {
      margin: 0;
      padding: tokens.$bpk-one-pixel-rem * 18;
    }

    $width: 17.19rem;
    $height: 16.72rem;

    .card {
      text-align: left;

      @include cards.bpk-card;

      &:hover {
        cursor: pointer;
      }

      &--dummy {
        width: $width;
        box-shadow: none;
        pointer-events: none;
      }
    }

    .card__body {
      width: $width;
      height: $height;
      border-radius: inherit;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: tokens.$bpk-color-white;
    }

    .card__footer {
      display: flex;
      width: $width;
      margin: 0 auto;
      padding: 0.77rem;
      padding-left: 1.17rem;
      align-items: center;
      border-radius: inherit;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background: white;

      @include breakpoints.bpk-breakpoint-mobile {
        @include typography.bpk-body-longform;
      }

      @include breakpoints.bpk-breakpoint-tablet {
        @include typography.bpk-body-longform;
      }
    }

    .job-words {
      padding-left: 0.3em;

      &--learn-more {
        padding-left: 0;
      }
    }

    .card__title {
      position: absolute;
      bottom: 3.5rem;
      padding: 0 1.1rem;

      h2,
      p {
        margin: 0;
        color: tokens.$bpk-color-white;
      }

      h2 {
        @include breakpoints.bpk-breakpoint-mobile {
          @include typography.bpk-hero-4;
        }

        @include breakpoints.bpk-breakpoint-tablet {
          @include typography.bpk-hero-4;
        }
      }

      p {
        @include breakpoints.bpk-breakpoint-mobile {
          @include typography.bpk-body-longform;
        }

        @include breakpoints.bpk-breakpoint-tablet {
          @include typography.bpk-body-longform;
        }
      }
    }

    .right-chevron-icon {
      margin-left: 0.25rem;
      fill: tokens.$bpk-color-sky-blue;
    }

    .card--early-careers {
      .card__body {
        background: tokens.$bpk-marcomms-eco-green;
      }
    }

    // team card specific styles
    .card--teams {
      .card__body {
        background: tokens.$bpk-color-sky-blue;
      }
    }

    $card-icon-size: 3.5rem;

    .card__icon {
      float: right;

      // important is required here to override the hardcoded backpack icon sizes
      // since they are inlined and cannot be overwritten be higher specificity
      width: $card-icon-size !important; /* stylelint-disable-line declaration-no-important */
      height: $card-icon-size !important; /* stylelint-disable-line declaration-no-important */
      margin-top: 1.6rem !important; /* stylelint-disable-line declaration-no-important */
      margin-right: 1.8rem;
      fill: tokens.$bpk-color-white;

      @include icons.bpk-icon-lg--align-to-large-button;
    }

    // early-careers specific
  }
}
