@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .offices-body {
      margin-bottom: 4rem;
    }

    .offices-header {
      margin-top: 3.5rem;
    }

    .card--bcn {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/barcelona.2197d79005814bb56b22.jpg');
      background-size: cover;
    }

    .card--edi {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/edinburgh.38549b6f6c6c43929673.jpg');
      background-size: cover;
    }

    .card--gla {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/glasgow.31947cab4330af2f7356.jpg');
      background-size: cover;
    }

    .card--ldn {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/london.216044aff2796f237c10.jpg');
      background-size: cover;
    }

    .card--mia {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/miami.1ad993e302f9f04a0cf9.jpg');
      background-size: cover;
    }

    .card--szx {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/shenzhen.8edb5fab3ebebbf8cbd0.jpg');
      background-size: cover;
    }

    .card--tyo {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/tokyo.3a25396b1a1ea7b52b75.jpg');
      background-size: cover;
    }

    .card--sin {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/singapore.13ab63eaaad6eaf65617.jpg');
      background-size: cover;
    }

    .columns > .card-container {
      margin: 0;
      padding: 0.5rem;
    }

    /* stylelint-disable selector-max-id */
    #life-section2-col1-header,
    #life-section2-col2-header,
    #life-section2-col3-header,
    #life-section4-col1-header,
    #life-section4-col2-header,
    #life-section4-col3-header,
    #life-section4-col4-header,
    #life-section4-col5-header {
      // these elements should match the 'h3' settings but must be an 'h2' in the html for accessibility
      margin: 0 0 tokens.bpk-spacing-lg();
      letter-spacing: normal;

      @include typography.bpk-heading-2;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-heading-1;
      }
    }

    .our-locations-text {
      margin-bottom: 5rem;
    }
  }
}
