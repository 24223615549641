@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .life-icon,
    .city-center-icon--large,
    .heart-icon--blue,
    .food-icon {
      display: inline-block;

      // important is required here to override the hardcoded backpack icon sizes
      // since they are inlined and cannot be overwritten be higher specificity
      width: 3.125rem !important; /* stylelint-disable-line declaration-no-important */
      height: 3.125rem !important; /* stylelint-disable-line declaration-no-important */
      margin-top: 0;
      margin-bottom: 1rem !important; /* stylelint-disable-line declaration-no-important */
      background-size: cover;
      vertical-align: top;
      fill: tokens.$bpk-color-sky-blue;
    }
  }
}
