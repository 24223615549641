@use '@skyscanner/backpack-web/unstable__bpk-mixins/buttons';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';
@use 'styles/cards';
@use 'styles/current-jobs';
@use 'styles/grid';
@use 'styles/header';
@use 'styles/hero-images';
@use 'styles/offices';
@use 'styles/icons';
@use 'styles/life';
@use 'styles/job-listing';
@use 'styles/teams';
@use 'styles/text';
@use 'styles/images';
@use 'styles/how-to-apply';
@use 'styles/early-careers';
@use 'styles/candidate-prep-pack';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    $title-colour: white;
    /* stylelint-disable-next-line unit-disallowed-list */
    $photo-border-radius: 9.7px;
    /* stylelint-disable-next-line unit-disallowed-list */
    $video-max-height: 770px;

    /* stylelint-disable selector-max-type */
    h1 {
      color: tokens.$bpk-marcomms-dark-sky;

      @include typography.bpk-heading-1;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-hero-3;
      }
    }

    h2 {
      margin: 0 0 tokens.bpk-spacing-lg();
      color: tokens.$bpk-marcomms-dark-sky;

      @include typography.bpk-heading-1;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-hero-4;
      }
    }

    h3 {
      margin: 0 0 tokens.bpk-spacing-lg();
      color: tokens.$bpk-marcomms-dark-sky;

      @include typography.bpk-heading-2;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-heading-1;
      }
    }

    h4 {
      margin: 0 0 tokens.bpk-spacing-lg();
      color: tokens.$bpk-marcomms-dark-sky;

      @include typography.bpk-heading-2;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-hero-4;
      }
    }

    p,
    a,
    li,
    dt,
    dd {
      color: tokens.$bpk-marcomms-dark-sky;

      @include typography.bpk-body-default;

      @include breakpoints.bpk-breakpoint-above-mobile {
        @include typography.bpk-body-longform;
      }
    }

    a {
      color: tokens.$bpk-color-sky-blue;
      text-decoration: none;
    }

    img {
      border-radius: $photo-border-radius;
    }

    hr {
      display: block;
      margin: tokens.bpk-spacing-md() 0;
      border: 0;
      border-bottom: tokens.$bpk-border-size-sm solid
        tokens.$bpk-surface-highlight-day;
      text-align: left;

      @include breakpoints.bpk-breakpoint-above-tablet {
        margin: tokens.bpk-spacing-lg() 0;
      }

      &.blue-line {
        width: 4.7rem;
        border: tokens.$bpk-border-size-lg solid tokens.$bpk-color-sky-blue;
      }
    }
    /* stylelint-enable selector-max-type */

    .jobssite-body {
      overflow-x: hidden;
    }

    .center-row {
      @include breakpoints.bpk-breakpoint-above-tablet {
        display: flex;
        align-items: center;
      }
    }

    .post-title {
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .post-location {
      margin-bottom: 4rem;
    }

    .contrast-panel {
      background: tokens.$bpk-canvas-contrast-day;
    }

    .center {
      text-align: center;
    }

    %button {
      @include buttons.bpk-button;
      @include buttons.bpk-button--featured;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include buttons.bpk-button--large;
      }

      svg {
        vertical-align: -0.375rem;
        fill: currentcolor;
      }
    }

    .button {
      @extend %button;
    }

    .button--white {
      @extend %button;

      @include buttons.bpk-button--secondary;
      /* stylelint-disable-next-line */
      background-color: tokens.$bpk-color-white;
      color: tokens.$bpk-color-sky-blue;
    }

    .button--disabled {
      @extend %button;

      background-color: rgb(221, 221, 229);
      background-image: none;
      color: rgba(0, 0, 0, 0.2);
      pointer-events: none;
    }

    .≈ {
      display: flex;
      padding: 1rem 0;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: left;
      gap: 0.25rem;

      @include breakpoints.bpk-breakpoint-tablet {
        padding: 0;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    .job_apply {
      @include buttons.bpk-button;
    }

    .content-block {
      padding-top: 4rem;
      padding-bottom: 4rem;

      @include breakpoints.bpk-breakpoint-tablet {
        padding-top: tokens.$bpk-one-pixel-rem * 30;
        padding-bottom: tokens.$bpk-one-pixel-rem * 30;
      }

      @include breakpoints.bpk-breakpoint-mobile {
        padding-top: tokens.$bpk-one-pixel-rem * 30;
        padding-bottom: tokens.$bpk-one-pixel-rem * 30;
      }
    }

    .desktop-only {
      @include breakpoints.bpk-breakpoint-tablet {
        display: none;
      }
    }

    .mobile-and-tablet-only {
      @include breakpoints.bpk-breakpoint-above-tablet {
        display: none;
        width: 0;
        flex: 0;
      }
    }

    $icon-size: 1.5rem;

    .icon {
      width: $icon-size;
      height: $icon-size;
      fill: tokens.$bpk-color-sky-blue;

      &:hover {
        opacity: 07;
      }
    }

    .skyscanner-quote {
      margin: 0 0 tokens.bpk-spacing-lg();
      flex-basis: calc(70% - #{tokens.bpk-spacing-lg()});
    }

    .search-button__container {
      float: right;
      width: 100%;

      @include breakpoints.bpk-breakpoint-above-mobile {
        width: 19%;
      }

      @include breakpoints.bpk-breakpoint-above-tablet {
        width: 10%;
      }
    }

    .search-header {
      margin-bottom: 1rem;
      color: tokens.$bpk-marcomms-dark-sky;
      text-align: left;
    }

    .current-job__button {
      float: right;
      width: 100%;
      height: 100%;
    }

    .form__input {
      display: inline-block;
      width: 100%;
      height: 3rem;
      padding: 0.375rem 0.75rem;
      border: none;
      background-color: tokens.$bpk-canvas-contrast-day;

      @include breakpoints.bpk-breakpoint-mobile {
        border-radius: 0.2rem;
      }

      @include breakpoints.bpk-breakpoint-tablet {
        border-radius: 0.2rem 0 0 0.2rem;
      }

      &::placeholder {
        color: tokens.$bpk-marcomms-grey-40;

        @include typography.bpk-text--base;
      }
    }

    .columns {
      display: flex;
      flex-wrap: wrap;

      > * {
        margin: tokens.$bpk-one-pixel-rem * 12;
      }

      &.columns__count-2 > * {
        flex-basis: calc(50% - #{tokens.$bpk-one-pixel-rem * 30});
      }

      &.columns__count-3 > * {
        flex-basis: calc(33% - #{tokens.bpk-spacing-lg()});
      }

      &.columns__count-4 > * {
        flex-basis: calc(25% - #{tokens.$bpk-one-pixel-rem * 30});
      }

      &.columns__count--tablet-2 > * {
        @include breakpoints.bpk-breakpoint-tablet {
          flex-basis: calc(50% - #{tokens.bpk-spacing-lg()});
        }
      }

      &.columns__count--tablet-1 > * {
        @include breakpoints.bpk-breakpoint-tablet {
          flex-basis: calc(100% - #{tokens.bpk-spacing-lg()});
        }
      }

      &.columns__count--tablet-3 > * {
        @include breakpoints.bpk-breakpoint-tablet {
          flex-basis: calc(33% - #{tokens.bpk-spacing-lg()});
        }
      }

      &.columns__count--mobile-1 > * {
        @include breakpoints.bpk-breakpoint-mobile {
          flex-basis: calc(100% - #{tokens.bpk-spacing-lg()});
        }
      }

      &.columns__count--mobile-2 > * {
        @include breakpoints.bpk-breakpoint-mobile {
          flex-basis: calc(50% - #{tokens.bpk-spacing-lg()});
        }
      }
    }

    .sub-heading {
      color: tokens.$bpk-marcomms-dark-sky;

      @include typography.bpk-subheading;
    }

    .home__section {
      h2 {
        @include typography.bpk-hero-4;

        @include breakpoints.bpk-breakpoint-tablet {
          @include typography.bpk-hero-5;
        }

        @include breakpoints.bpk-breakpoint-mobile {
          @include typography.bpk-hero-5;
        }
      }

      p {
        @include typography.bpk-body-longform;
      }
    }

    .quote {
      @include typography.bpk-heading-2;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-hero-5;
      }
    }

    .home__heading {
      color: $title-colour;

      @include typography.bpk-heading-1;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-hero-3;
      }
    }

    .greenhouse-wrapper {
      width: calc(100vw - 1.25rem);
      margin-left: calc(-50vw + 50% + 0.625rem);
    }

    .photo {
      max-width: 100%;
      border-radius: $photo-border-radius;
    }

    .photo-container {
      margin-right: 3.5rem;
    }

    .photo-container--left {
      flex: 0;
    }

    .photo-container--right {
      @include breakpoints.bpk-breakpoint-above-tablet {
        margin-left: 3.5rem;
      }
    }

    .video-container--portrait {
      display: flex;
      margin-right: 3.5rem;
      justify-content: space-around;
    }

    .video-container--landscape {
      display: flex;
      justify-content: space-around;
    }

    .video {
      max-width: 100%;
      max-height: $video-max-height;
      border-radius: $photo-border-radius;
    }

    .glassdoor {
      width: 28rem;
      margin: 0 auto;
      text-align: center;

      @include breakpoints.bpk-breakpoint-tablet {
        width: 60%;
      }

      @include breakpoints.bpk-breakpoint-mobile {
        width: 70%;
      }
    }

    .sub-footer {
      border-top: tokens.$bpk-border-size-sm solid
        tokens.$bpk-color-sky-gray-tint-07;

      @include typography.bpk-text--sm;
      @include typography.bpk-text--bold;

      .sub-footer__links {
        @include breakpoints.bpk-breakpoint-above-tablet {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }

    .footer-links {
      display: flex;
      margin: 0;
      padding: 0;
      justify-content: center;
      list-style: none;

      @include breakpoints.bpk-breakpoint-above-tablet {
        justify-content: space-between;
      }

      a {
        display: block;
        padding: 0.75rem;
        text-align: center;
        text-decoration: none;

        @include typography.bpk-text--sm;
      }
    }

    .internal {
      @include breakpoints.bpk-breakpoint-mobile {
        display: block;

        a {
          text-align: left;
        }
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .social {
      svg {
        fill: tokens.$bpk-color-sky-blue;
      }

      span {
        position: absolute;
        left: -999em;
        overflow: hidden;
      }
    }

    .job-unavailable-msg {
      padding: 10rem;
      text-align: center;
    }
  }
}
