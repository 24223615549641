@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .grid__container {
      max-width: 80rem;
      margin: 0 auto;

      // 0.75 padding left + right was included in the mixin but never used
      padding: 0 2rem;

      @include breakpoints.bpk-breakpoint-above-tablet {
        padding: 0 3rem;
      }

      @media (min-width: 92rem) {
        padding: 0;
      }
    }

    .grid__container--full-width {
      position: relative;
      max-width: 100%;
      overflow: hidden;
    }

    .grid__background--gray {
      background-color: tokens.$bpk-canvas-contrast-day;
    }

    .grid__background--blue {
      background-color: tokens.$bpk-color-sky-blue;
    }

    .grid__background--blue-and-gray {
      background: tokens.$bpk-canvas-contrast-day;

      @include breakpoints.bpk-breakpoint-above-tablet {
        background: linear-gradient(
          90deg,
          tokens.$bpk-color-sky-blue 50%,
          #eff1f2 50%
        );
      }
    }

    .grid__row {
      margin-right: -0.75rem;
      margin-left: -0.75rem;
      overflow: hidden; // required to give the element height
    }

    .grid__column--padded {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    .header-container {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .grid__column--12 {
      float: left;
      display: block;
      width: 100%;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .grid__column--9 {
      float: left;
      display: block;
      width: 75%;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .grid__column--3 {
      float: left;
      display: block;
      width: 25%;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .grid__column--4 {
      float: left;
      display: block;
      width: 33.3333%; // not a fan of this tomfoolery
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .grid__column--5 {
      float: left;
      display: block;
      width: 41.6667%;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .grid__column--6 {
      float: left;
      display: block;
      width: 50%;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .grid__column--7 {
      float: left;
      display: block;
      width: 58.3333%;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .grid__column--tablet-12 {
      float: left;
      padding-right: 0.75rem;
      padding-left: 0.75rem;

      @media (max-width: 64rem) {
        display: block;
        width: 100%;
      }
    }

    .grid__column--tablet-12 + .grid__column--tablet-12 {
      @include breakpoints.bpk-breakpoint-tablet {
        margin-top: 1.5rem;
      }
    }

    .grid__column--mobile-12 {
      float: left;
      padding-right: 0.75rem;
      padding-left: 0.75rem;

      @media (max-width: 32rem) {
        display: block;
        width: 100%;
      }
    }

    .grid__column--mobile-12 + .grid__column--mobile-12 {
      @include breakpoints.bpk-breakpoint-mobile {
        margin-top: tokens.bpk-spacing-md();
      }
    }

    .space-after-text {
      margin-bottom: 1.5rem;
      // stylelint-disable-next-line scale-unlimited/declaration-strict-value
      font-size: 1.25rem;
    }

    .list-item {
      // stylelint-disable-next-line scale-unlimited/declaration-strict-value
      line-height: 2rem;
    }
  }
}
