@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

$sky-blue-linear-gradient: linear-gradient(rgba(17, 18, 54, 0.8), transparent);
$sky-light-blue-linear-gradient: linear-gradient(
  rgba(17, 18, 54, 0.6),
  transparent
);

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .hero {
      position: relative;
      display: flex;
      height: 13.5rem;
      flex-direction: column;
      justify-content: space-between;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      color: tokens.$bpk-color-white;
      text-align: center;

      @include breakpoints.bpk-breakpoint-above-tablet {
        height: 26.709rem;

        h1 {
          margin-bottom: 5rem;
        }
      }
    }

    .hero.home-banner {
      height: fit-content;

      @include breakpoints.bpk-breakpoint-above-mobile {
        height: 21.531rem;
      }

      @include breakpoints.bpk-breakpoint-above-tablet {
        height: 47.888rem;
      }

      h1 {
        margin-top: 1rem;

        @include breakpoints.bpk-breakpoint-above-mobile {
          margin-top: 5rem;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          margin-top: 12rem;
        }
      }

      a {
        margin-bottom: 2rem;
      }
    }

    .hero h1 {
      margin-top: 6rem;
      margin-bottom: 2rem;
      padding-top: 2rem;

      @include typography.bpk-hero-2;

      @include breakpoints.bpk-breakpoint-tablet {
        margin-top: 5rem;
        margin-bottom: tokens.bpk-spacing-md();
        padding-top: tokens.bpk-spacing-md();

        @include typography.bpk-hero-5;
      }

      @include breakpoints.bpk-breakpoint-mobile {
        margin-top: 5rem;

        @include typography.bpk-hero-5;
      }
    }

    .hero.job-post-banner h1 {
      @include typography.bpk-text--xl;
    }

    .hero.gradsinterns-banner h1 {
      @include breakpoints.bpk-breakpoint-mobile {
        margin-bottom: 3.5rem;
        padding-right: 5rem;
        text-align: left;
      }
    }

    .hero h2 {
      margin: 0.75rem auto;

      @include typography.bpk-text--xxl;
    }

    .hero > div {
      width: 100%;
    }

    .hero label {
      @include typography.bpk-text--base;
    }

    .home-banner {
      background-image: url('https://content.skyscnr.com/m/3a18b85e51a5ed46/Homepage_Hero-Homepage_Herobanner_2000x1197.png?imbypass=true');
      background-position: center top;
    }

    .life-banner {
      background-image: url('https://content.skyscnr.com/m/6cd6ff686510e8b7/Homepage_Hero-LifeAtSkyscanner_Header_2000x900.png?imbypass=true');
    }

    .jobs-banner {
      background-image: url('https://content.skyscnr.com/m/5a2b3bd12e7d26d7/Homepage_Hero-CurrentJobs_Header_2000x900.png?imbypass=true');
    }

    .early-careers-banner {
      background-image: url('https://content.skyscnr.com/m/79bd83372875460d/Homepage_Hero-EarlyCareers_Header_2000x900.png?imbypass=true');
    }

    .teams-banner {
      background-image: url('https://content.skyscnr.com/m/3cfd7534ebf9dc08/Homepage_Hero-Teams_Header_2000x900.png?imbypass=true');
    }

    .offices-banner {
      background-image: url('https://content.skyscnr.com/m/397de947f67d1783/Homepage_Hero-Offices_Header_2000x900.png?imbypass=true');
    }

    .barcelona-banner {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/barcelona.5fc29c2e6d082c86d9fb.jpg');
    }

    .edinburgh-banner {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/edinburgh.8304b0e9079325a77422.jpg');
    }

    .glasgow-banner {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/glasgow.56346b4e40fc503da1e1.jpg');
    }

    .london-banner {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/london.0d131e3da9f4ff6bdc48.jpg');
    }

    .miami-banner {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/miami.67253656f2e7f5f27f97.jpg');
    }

    .shenzhen-banner {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/shenzhen.b0104d7513c4f9ab50f6.jpg');
    }

    .singapore-banner {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/singapore.bde14f38b257a38f0caa.jpg');
    }

    .tokyo-banner {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/tokyo.42a3b927290ac69f78cb.jpg');
    }
  }
}
