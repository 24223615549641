@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .how-to-apply-main-header-container {
      padding-bottom: 1rem;
    }

    .how-to-apply-main-header {
      margin-top: 1rem;
    }

    .how-to-apply-header {
      @include typography.bpk-heading-2;

      /* stylelint-disable-next-line */
      margin-bottom: 0;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-hero-4;
      }
    }

    .how-to-apply-list {
      line-height: tokens.$bpk-line-height-xl;

      li {
        margin-top: 0;
        margin-bottom: 0;
        line-height: tokens.$bpk-line-height-xl;
      }

      dt {
        margin-top: 1rem;

        @include typography.bpk-text--bold;
      }

      dd {
        margin: 0;
      }
    }

    $how-to-apply-icon-mobile-size: 2.5rem;
    $how-to-apply-icon-size: 3.75rem;

    .how-to-apply-icon {
      position: relative;
      float: left;
      width: $how-to-apply-icon-mobile-size;
      height: $how-to-apply-icon-mobile-size;
      margin-right: 1rem;
      border-radius: 50%;
      background: tokens.$bpk-color-sky-blue;
      line-height: $how-to-apply-icon-mobile-size;
      text-align: center;
      vertical-align: middle;

      @include breakpoints.bpk-breakpoint-above-tablet {
        float: none;
        width: 3.75rem;
        height: 3.75rem;
        /* stylelint-disable-next-line */
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        /* stylelint-disable-next-line */
        line-height: 3.75rem;
      }
    }

    .how-to-apply-icon--lower-section {
      @include breakpoints.bpk-breakpoint-above-tablet {
        float: left;
        /* stylelint-disable-next-line */
        line-height: 2.9rem;
      }
    }

    .how-to-apply-icon__number {
      color: tokens.$bpk-color-white;
      /* stylelint-disable-next-line */
      font-size: 2rem;
      font-weight: 700;

      @include breakpoints.bpk-breakpoint-above-tablet {
        /* stylelint-disable-next-line */
        font-size: 2.5rem;
      }
    }

    .space-after-text {
      margin-bottom: 1.5rem;
      /* stylelint-disable-next-line */
      font-size: 1.25rem;
      /* stylelint-disable-next-line */
      line-height: 2rem;
    }

    .text-container {
      padding-bottom: 3rem;
    }

    .how-to-apply {
      display: flex;
      flex-direction: column;

      &__body {
        order: 2;
        flex: 5;
      }

      img {
        width: 18rem;
        height: 18rem;
        margin: auto;
        margin-bottom: 2rem;
        order: 1;
        flex: 1;
      }
      /* stylelint-disable-next-line */
      @include breakpoints.bpk-breakpoint-above-mobile {
        flex-direction: row;

        &__body {
          order: 1;
        }

        img {
          margin-bottom: 0;
          order: 2;
        }
      }
    }

    .how-to-apply-sub-header {
      @include typography.bpk-heading-2;

      @include breakpoints.bpk-breakpoint-above-tablet {
        @include typography.bpk-heading-1;
      }
    }

    .how-to-apply-overview {
      @include breakpoints.bpk-breakpoint-above-tablet {
        letter-spacing: -0.02em;
      }
    }

    .how-to-apply-tips {
      margin-top: 2rem;
      margin-bottom: 0.2rem;
    }

    .how-to-apply-your-cv {
      margin-bottom: 0;
    }

    .how-to-apply-unordered-list {
      margin-top: 0;
      padding-left: 2rem;
    }

    .how-to-apply-second-unordered-list {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 2rem;
    }

    .how-to-apply-the-interview-container {
      padding-bottom: 3rem;
    }

    .how-to-apply-discovery-day-item {
      margin-top: 0.2rem;
    }

    .how-to-apply-discovery-day-second-item {
      margin-bottom: 1.5rem;
    }
  }
}
