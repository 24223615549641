@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/forms';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .search-form {
      display: flex;
      flex-wrap: wrap;

      @include breakpoints.bpk-breakpoint-above-tablet {
        padding: 1rem 0;
      }

      select,
      input {
        min-height: 3rem;
        padding-left: 0.78rem;
        border: none;
        background-color: tokens.$bpk-canvas-contrast-day;
        color: tokens.$bpk-color-sky-gray;

        :focus {
          border: none;
        }
      }
    }

    .search-form__select-container {
      height: 3.5rem;
      flex-basis: 100%;

      @include breakpoints.bpk-breakpoint-above-mobile {
        flex-basis: calc(50% - 0.25rem);
      }

      @include breakpoints.bpk-breakpoint-above-tablet {
        margin-right: 0.25rem;
        flex: 2;
        flex-basis: auto;
      }
    }

    .search-form__departments-container {
      @include breakpoints.bpk-breakpoint-above-mobile {
        margin-right: 0.25rem;
      }
    }

    .search-form__input-container {
      height: 3.5rem;
      flex: 4;
      flex-basis: 100%;

      @include breakpoints.bpk-breakpoint-above-mobile {
        margin-top: 0;
        flex-basis: auto;
      }
    }

    .search-form__button {
      margin-left: 1rem;
      flex: 1;
      border-radius: 0.2rem;

      @include breakpoints.bpk-breakpoint-mobile {
        width: 100%;
      }

      @include breakpoints.bpk-breakpoint-tablet {
        width: 20%;
      }
    }

    .choices[data-type*='select-one'] .choices__inner {
      padding-bottom: 0.25rem;
    }

    %choices-inner-mid {
      border-radius: 0.2rem 0 0 0.2rem;

      @include typography.bpk-text--base;

      @include breakpoints.bpk-breakpoint-mobile {
        border-radius: 0.2rem;
      }
    }

    .choices__inner_mid {
      @extend %choices-inner-mid;
    }

    %choices-inner-right {
      border-width: 0.0625rem;

      @include typography.bpk-text--base;

      @include breakpoints.bpk-breakpoint-mobile {
        border-radius: 0.2rem;
      }

      @include breakpoints.bpk-breakpoint-tablet {
        border-radius: 0 0.2rem 0.2rem 0;
      }
    }

    .choices__inner_right {
      @extend %choices-inner-right;
    }

    .is-focused > .choices__inner_mid {
      @extend %choices-inner-mid;

      z-index: 3000;
      outline: -webkit-focus-ring-color auto 0.3125rem;
      outline-offset: -0.125rem;
    }

    .is-focused > .choices__inner_right {
      @extend %choices-inner-right;

      z-index: 3000;
      outline: -webkit-focus-ring-color auto 0.3125rem;
      outline-offset: -0.125rem;
    }

    .choices__input {
      background-color: tokens.$bpk-color-white;

      @include forms.bpk-input--large;
    }

    .choices__list[aria-expanded] .choices__item--selectable {
      padding-right: 0;

      @include typography.bpk-text--base;
    }

    .job-list-container {
      .jobs-list-spinner {
        width: tokens.bpk-spacing-xxxl();
        height: tokens.bpk-spacing-xxxl();
        align-self: center;
      }
    }
  }
}
