@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .links-row {
      display: none;
      margin: auto;
      flex-direction: column;

      h2 {
        @include typography.bpk-hero-5;
      }
      /* stylelint-disable-next-line */
      @include breakpoints.bpk-breakpoint-above-mobile {
        flex-direction: row;
      }

      @include breakpoints.bpk-breakpoint-above-tablet {
        display: flex;
      }
    }

    .link-body--left {
      color: tokens.$bpk-color-white;

      @include breakpoints.bpk-breakpoint-above-tablet {
        padding-right: 4.7rem;
      }
    }

    .link-body--right {
      @include breakpoints.bpk-breakpoint-above-tablet {
        padding-left: 4.7rem;
      }
    }

    .link-body__logo {
      float: none;
      display: none;
      margin-bottom: 2.4rem;
      background-repeat: no-repeat;
      background-position: left;

      @include breakpoints.bpk-breakpoint-above-tablet {
        display: inherit;
      }
    }

    .mobile-logo-container {
      display: inherit;

      @include breakpoints.bpk-breakpoint-above-tablet {
        display: none;
      }

      .win-internship-logo {
        display: inherit;
        height: 6.5rem;
        background-size: 16.38rem;
      }

      .forage-logo {
        display: inherit;
        background-size: 16.38rem;
      }
    }

    .mobile-logo-container--white {
      color: tokens.$bpk-color-white;
    }

    .win-internship-logo {
      background-image: url('https://content.skyscnr.com/m/273c942a6cbd7531/original/undergraduate-of-the-year.png');
      background-repeat: no-repeat;
      background-size: 18rem;
    }

    .forage-logo {
      background-image: url('https://css.skyscnr.com/sttc/jobssite/img/forage-logo.bde6310110daf68f72db.png');
      background-repeat: no-repeat;
      background-size: 19.3rem;
    }

    .link-body .button {
      max-width: 13rem;
    }

    .how-to-apply {
      display: flex;
      flex-direction: column;

      &__body {
        order: 2;
        flex: 5;
      }

      img {
        width: 18rem;
        height: 18rem;
        margin: auto;
        margin-bottom: 2rem;
        order: 1;
        flex: 1;
      }
      /* stylelint-disable-next-line */
      @include breakpoints.bpk-breakpoint-above-mobile {
        flex-direction: row;

        &__body {
          order: 1;
        }

        img {
          margin-bottom: 0;
          order: 2;
        }
      }
    }

    .personal-development {
      // should not override inherited side padding
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .win-internship-text {
      color: tokens.$bpk-color-white;
    }
  }
}
