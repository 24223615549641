@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/cards';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/icons';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .current-jobs-container {
      padding-bottom: 1rem;
      overflow: inherit;
    }

    .job-list-container {
      display: flex;
      min-height: 3rem;
      margin-top: 1rem;
      flex-direction: column;
      align-content: space-between;
      gap: 1rem;
    }

    .job__container {
      margin-bottom: 7rem;

      @include breakpoints.bpk-breakpoint-tablet {
        margin-bottom: 6rem;
      }

      @include breakpoints.bpk-breakpoint-mobile {
        margin-bottom: 5rem;
      }

      .register-interest-text {
        padding-left: 0.8rem;
      }
    }

    .job-list-title {
      margin-top: 3rem;
      margin-bottom: 4rem;
    }

    .job-listing {
      @include cards.bpk-card;

      p {
        margin-bottom: 0;
      }

      .job-listing__icon-container {
        width: 1.5rem;
        height: 1.5rem;
        margin: auto 0;
      }

      .right-chevron-icon {
        position: relative;
        margin-left: 0; // overrides the inherited Card property
        fill: tokens.$bpk-color-sky-blue;
      }

      a {
        position: relative;
        display: flex;
        height: 100%;
        padding: tokens.$bpk-one-pixel-rem * 30;
        justify-content: space-between;
        color: tokens.$bpk-marcomms-dark-sky;
        cursor: pointer;

        @include breakpoints.bpk-breakpoint-tablet {
          padding: tokens.$bpk-one-pixel-rem * 18;
        }
      }

      .job-title,
      .job-department {
        margin: 0;
        // stylelint-disable-next-line backpack/use-typography-styles
        letter-spacing: inherit;

        @include typography.bpk-heading-3;

        @include breakpoints.bpk-breakpoint-above-tablet {
          @include typography.bpk-heading-2;
        }
      }

      .job-department {
        // stylelint-disable-next-line backpack/use-typography-styles
        font-weight: 400;
      }

      .job-location {
        margin-top: tokens.bpk-spacing-lg();

        @include typography.bpk-body-default;

        @include breakpoints.bpk-breakpoint-above-tablet {
          margin-top: tokens.bpk-spacing-lg();

          @include typography.bpk-body-longform;
        }
      }
    }

    .city-center-icon {
      margin-right: 0.25rem;
      fill: tokens.$bpk-color-sky-blue;

      @include icons.bpk-icon-lg--align-to-large-button;

      @include breakpoints.bpk-breakpoint-above-tablet {
        // important is required here to override the hardcoded backpack icon sizes
        // since they are inlined and cannot be overwritten be higher specificity
        width: 1.8rem !important; /* stylelint-disable-line declaration-no-important */
        height: 1.8rem !important; /* stylelint-disable-line declaration-no-important */
        margin-right: tokens.bpk-spacing-md();
      }
    }

    .job-list-count-word {
      // stylelint-disable-next-line backpack/use-typography-styles
      font-weight: 400;
    }

    .job-list__button-container {
      display: flex;
      justify-content: center;
    }

    .job-list__button {
      margin-top: 0.75rem;

      @include typography.bpk-body-longform;

      span {
        padding-left: 0.4rem;
      }
    }

    .jobs__linkedin-tag {
      color: tokens.$bpk-color-white;
    }
  }
}
