@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';
@use 'mixins';

.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    $prep-pack-dark-blue: #084eb2;
    $prep-pack-light-blue: #d9f8ff;
    $breakpoint-above-mobile: 32.0625rem;
    $breakpoint-above-tablet: 64.0625rem;
    $prep-pack-paragraph-large-font-size: 1.75rem; // prep pack designed with text between backpack sizes

    .candidate-prep-pack-container {
      h2 {
        @include typography.bpk-heading-2;

        @include breakpoints.bpk-breakpoint-above-mobile {
          @include typography.bpk-hero-4;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          @include typography.bpk-hero-2;
        }
      }

      h3 {
        @include typography.bpk-heading-3;

        @include breakpoints.bpk-breakpoint-above-mobile {
          @include typography.bpk-hero-4;
        }
      }

      %paragraph {
        font-size: tokens.$bpk-font-size-sm;
        line-height: tokens.$bpk-line-height-sm;

        @include breakpoints.bpk-breakpoint-above-mobile {
          font-size: tokens.$bpk-font-size-lg;
          line-height: tokens.$bpk-line-height-lg;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          font-size: $prep-pack-paragraph-large-font-size;
          line-height: tokens.$bpk-line-height-xxl;
        }
      }

      p {
        @extend %paragraph;

        margin-top: 0;
        margin-bottom: tokens.bpk-spacing-base();
      }

      .row {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        text-align: center;

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: 100%;
          min-width: $breakpoint-above-tablet;
          max-width: 75rem;
        }
      }

      .section-padding {
        padding: tokens.bpk-spacing-lg() 0;

        @include breakpoints.bpk-breakpoint-above-mobile {
          padding: 3rem 0;
        }
      }

      .overlay,
      %overlay {
        width: 100%;
        height: 1.875rem;
        margin-top: auto;
        align-self: flex-end;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include breakpoints.bpk-breakpoint-above-mobile {
          height: 3.125rem;
        }
      }

      // Hero

      .prep-pack-hero {
        h1,
        p {
          color: tokens.$bpk-color-white;
        }
      }

      .image-container {
        display: flex;
        height: 8.75rem;
        flex-direction: column;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include breakpoints.bpk-breakpoint-above-mobile {
          height: 18.75rem;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          height: 36.25rem;
        }
      }

      .hero-image-container {
        height: 12.5rem;
        background-image: url('https://content.skyscnr.com/m/1b25dd10dade5dec/original/Candidates-Page_Hero-Image_1920x1080.png?imbypass=true');

        @include breakpoints.bpk-breakpoint-above-mobile {
          height: 18.75rem;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          height: 36.25rem;
        }
      }

      .logo-container {
        position: relative;
        top: -0.5rem;
        left: 0.5rem;
        display: flex;
        justify-content: left;

        @include breakpoints.bpk-breakpoint-above-mobile {
          top: -2rem;
          left: 1.5rem;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          top: -7.5rem;
          left: 1.5rem;
        }
      }

      .logo-image {
        width: 6rem;

        @include breakpoints.bpk-breakpoint-above-mobile {
          width: 10rem;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: unset;
        }
      }

      .strapline {
        position: relative;
        width: 18.75rem;
        margin: 0;
        align-self: center;
        text-align: center;

        @include typography.bpk-hero-6;

        @include breakpoints.bpk-breakpoint-above-mobile {
          width: 25rem;

          @include typography.bpk-hero-5;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: 53.25rem;

          @include typography.bpk-hero-2;
        }
      }

      .arrow-container {
        display: flex;
        height: 4.375rem;
        justify-content: center;
      }

      .scroll-down-arrow {
        width: 1.563rem;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        fill: tokens.$bpk-color-white;

        @include breakpoints.bpk-breakpoint-above-mobile {
          width: 2.188rem;
          margin-top: tokens.bpk-spacing-xl();
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: 3.125rem;
          margin-top: tokens.bpk-spacing-xl();
        }
      }

      .real-deal-container {
        background-color: $prep-pack-dark-blue;
      }

      // The Story So Far

      .story-so-far {
        background-color: tokens.$bpk-marcomms-dark-sky;
      }

      .hidden {
        visibility: hidden;
      }

      .story-so-far-title {
        margin-bottom: tokens.bpk-spacing-lg();
        color: tokens.$bpk-color-white;
        letter-spacing: unset;
        text-align: center;

        @include breakpoints.bpk-breakpoint-above-mobile {
          margin-bottom: 3rem;
        }
      }

      .story-middle-row {
        display: flex;
        padding-right: 0.375rem;
        padding-left: 0.375rem;
        flex-direction: column;

        @include breakpoints.bpk-breakpoint-above-mobile {
          flex-direction: row;
        }
      }

      .left {
        width: 100%;
        padding: 0 0.375rem;
        flex: 1;

        @include breakpoints.bpk-breakpoint-above-mobile {
          padding: 0.375rem;
          flex: 5;
        }
      }

      .story-strapline {
        color: tokens.$bpk-color-white;
        font-weight: 700;
        text-align: left;

        @include breakpoints.bpk-breakpoint-above-mobile {
          @include typography.bpk-heading-1;
        }
      }

      .right {
        width: 100%;
        padding: 0 0.375rem;
        flex: 1;

        @include breakpoints.bpk-breakpoint-above-mobile {
          width: calc(7 / 12 * 100%);
          padding: 0.375rem 0.375rem 0.375rem tokens.bpk-spacing-lg();
          flex: 7;
        }
      }

      .bounce {
        @include mixins.bounce-animation;
      }

      .fade-in {
        @include mixins.fade-in-animation;
      }

      .fade-in-left {
        @include mixins.fade-in-left-animation;
      }

      .fade-in-right {
        @include mixins.fade-in-right-animation;
      }

      .story-paragraph-container {
        text-align: left;

        p {
          color: tokens.$bpk-color-white;
        }
      }

      .bottom-row {
        padding-top: 3rem;
        padding-bottom: 0;
      }

      p.terms {
        width: 100%;
        color: tokens.$bpk-color-white;
        text-align: center;

        @include typography.bpk-body-default;

        @include breakpoints.bpk-breakpoint-above-mobile {
          @include typography.bpk-body-longform;
        }
      }

      // Our Brand

      .brand-image-container {
        background-image: url('https://content.skyscnr.com/m/29e1e7288e397762/original/Candidates-Page_Image01_1920x1080.png?imbypass=true');
        background-position: center top;
        clip-path: inset(0);
      }

      .blurred {
        filter: blur(tokens.bpk-spacing-md());
      }

      .blur-in {
        filter: blur(tokens.bpk-spacing-md()); // initial state

        @include mixins.blur-animation;
      }

      .brand-content-container {
        position: relative;
      }

      .brand-row {
        display: flex;
        flex-direction: column;

        @include breakpoints.bpk-breakpoint-above-tablet {
          flex-direction: row;
        }
      }

      .our-brand-title {
        width: 18rem;
        flex: unset;
        text-align: left;
      }

      .our-brand-paragraph {
        width: 100%;
        flex: 1;
        text-align: left;

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: calc(7 / 12 * 100%);
          flex: 7;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: calc(9 / 12 * 100%);
        }
      }

      // Our Culture

      .culture-image-container {
        background-image: url('https://content.skyscnr.com/m/3994db4de040b8a5/original/Candidates-Page_Image02_1920x1080.png?imbypass=true');
        background-position: center;

        @include breakpoints.bpk-breakpoint-above-tablet {
          height: 43.75rem;
        }
      }

      .greyscaled {
        filter: grayscale(1);
      }

      .greyscale-in {
        filter: grayscale(1); // initial state

        @include mixins.greyscale-animation;
      }

      .culture-content-container {
        position: relative;
        background-color: $prep-pack-light-blue;
      }

      .culture-title-row {
        text-align: left;
      }

      .culture-content-row {
        display: flex;
        flex-direction: column;
        color: $prep-pack-light-blue;

        @include breakpoints.bpk-breakpoint-above-tablet {
          flex-direction: row;
        }
      }

      .culture-paragraph {
        width: 100%;
        text-align: left;

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: calc(9 / 12 * 100%);
        }
      }

      .culture-figures {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        font-size: tokens.$bpk-font-size-xxxl;

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: calc(3 / 12 * 100%);
          padding-left: tokens.bpk-spacing-xxl();
          flex-direction: column;
          justify-content: start;
          text-align: left;
        }
      }

      .culture-figures-descriptor {
        font-size: tokens.$bpk-font-size-xl;
        line-height: tokens.$bpk-line-height-xl;

        @include breakpoints.bpk-breakpoint-above-tablet {
          font-size: tokens.$bpk-font-size-xxxl;
          line-height: tokens.$bpk-line-height-xxxl;
        }
      }

      .culture-figures-number {
        font-size: tokens.$bpk-font-size-xxl;
        line-height: tokens.$bpk-line-height-xxl;

        @include breakpoints.bpk-breakpoint-above-tablet {
          font-size: tokens.$bpk-font-size-xxxxl;
          line-height: tokens.$bpk-line-height-xxxxl;
        }
      }

      // Exec Video

      .exec-video-container {
        width: 100%;
        vertical-align: bottom;
      }

      // Faces

      .faces-container {
        display: flex;
        flex-direction: column;

        @include breakpoints.bpk-breakpoint-above-tablet {
          flex-direction: row;
        }
      }

      .faces-left {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: end;
        background-color: tokens.$bpk-color-sky-blue;

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: 50%;
        }
      }

      .faces-text {
        width: 100%;
        padding: tokens.bpk-spacing-xxl();

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: 36.5rem;
          padding-left: tokens.bpk-spacing-lg();
        }

        > p {
          color: tokens.$bpk-color-white;

          > a {
            @extend %paragraph;

            color: tokens.$bpk-color-white;
            text-decoration: underline;
          }
        }
      }

      .faces-right {
        width: 100%;
        height: 31.2rem;
        background-image: url('https://content.skyscnr.com/m/3405d4358ff82157/original/Candidates-Page_Image03_1000x1000.png?imbypass=true');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;

        @include breakpoints.bpk-breakpoint-above-mobile {
          width: 100%;
          height: 53.3rem;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: 50%;
          height: 70rem;
          background-position: center;
        }
      }

      .fade-in-left-delay-1 {
        @include mixins.fade-in-left-animation-delay(1);
      }

      .fade-in-left-delay-15 {
        @include mixins.fade-in-left-animation-delay(1.5);
      }

      .fade-in-left-delay-2 {
        @include mixins.fade-in-left-animation-delay(2);
      }

      // How we move

      .how-we-move-image {
        height: 33.4rem;
        background-image: url('https://content.skyscnr.com/m/752b0aa9cd953ef1/original/Candidates-Page_Image04_1920x1080.png?imbypass=true');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include breakpoints.bpk-breakpoint-above-mobile {
          height: 66.8rem;
        }
      }

      .how-we-move-lower {
        display: flex;
        padding-right: tokens.bpk-spacing-base();
        padding-bottom: tokens.bpk-spacing-xxl();
        padding-left: tokens.bpk-spacing-base();
        justify-content: center;

        @include breakpoints.bpk-breakpoint-above-tablet {
          width: 100%;
          min-width: $breakpoint-above-tablet;
          max-width: 75rem;
          margin: auto;
        }
      }

      .how-we-move-content {
        display: flex;
        width: 100%;
        margin-top: -12.5rem;
        padding: tokens.bpk-spacing-lg();
        flex-direction: column;
        border-radius: tokens.bpk-spacing-xl();
        background-color: tokens.$bpk-marcomms-dark-sky;

        @include breakpoints.bpk-breakpoint-above-mobile {
          padding: tokens.bpk-spacing-xxl();
          flex-direction: row;
        }

        @include breakpoints.bpk-breakpoint-above-tablet {
          min-width: $breakpoint-above-tablet;
          max-width: 75rem;
        }

        h2,
        p,
        li,
        a {
          color: tokens.$bpk-color-white;
        }

        a {
          font-weight: bold;
          text-decoration: underline;
        }
      }

      .how-we-move-content-left {
        width: 100%;

        @include breakpoints.bpk-breakpoint-above-mobile {
          width: calc(5 / 12 * 100%);
          padding: tokens.bpk-spacing-md();
          padding-right: tokens.bpk-spacing-lg();
        }
      }

      .how-we-move-content-right {
        width: 100%;

        @include breakpoints.bpk-breakpoint-above-mobile {
          width: calc(7 / 12 * 100%);
          padding: tokens.bpk-spacing-md();
          padding-left: tokens.bpk-spacing-lg();
        }

        li {
          @extend %paragraph;

          > a {
            @extend %paragraph;
          }
        }
      }

      .how-we-move-title {
        width: 100%;

        @include breakpoints.bpk-breakpoint-above-mobile {
          max-width: 14.5rem;
          margin-bottom: tokens.bpk-spacing-xxl();
        }
      }

      .interview-process-section {
        background-color: tokens.$bpk-color-sky-blue;

        h2 {
          margin-bottom: tokens.bpk-spacing-xxl();
          color: tokens.$bpk-color-white;
        }

        .dropdown-list .dropdown-item {
          margin-bottom: tokens.bpk-spacing-lg();
        }

        hr {
          margin: tokens.bpk-spacing-lg() 0;
          padding: 0;
          border: 0;
          border-top: tokens.$bpk-border-size-sm solid
            tokens.$bpk-marcomms-dark-sky;
        }

        .dropdown-list .dropdown-item .dropdown-title {
          position: relative;
          display: flex;
          width: 100%;
          padding: tokens.bpk-spacing-base();
          align-items: center;
          border: none;
          border-radius: tokens.bpk-spacing-md();
          outline: 0;
          background-color: tokens.$bpk-marcomms-dark-sky;
          color: tokens.$bpk-color-white;
          letter-spacing: 0.2rem;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;

          @include typography.bpk-heading-2;

          @include breakpoints.bpk-breakpoint-above-mobile {
            @include typography.bpk-heading-2;
          }

          @include breakpoints.bpk-breakpoint-above-tablet {
            padding: 0;
            padding-right: tokens.bpk-spacing-base();

            @include typography.bpk-heading-2;
          }
        }

        .dropdown-title > span {
          width: 100%;
        }

        .dropdown-item__arrow {
          float: right;
          width: tokens.bpk-spacing-base();
          margin-left: auto;
          transition: transform 0.4s ease-in-out;
          fill: tokens.$bpk-color-white;

          @include breakpoints.bpk-breakpoint-above-mobile {
            width: tokens.bpk-spacing-lg();
          }

          @include breakpoints.bpk-breakpoint-above-tablet {
            width: 2.25rem;
          }

          &--rotate {
            transform: translateY(0%) rotate(90deg);
          }
        }

        .dropdown-list .dropdown-item .dropdown_body {
          display: block;
          height: auto;
          padding: tokens.bpk-spacing-xl();
          border-radius: tokens.bpk-spacing-md();
          background-color: tokens.$bpk-color-white;
          text-align: left;
          overflow: hidden;

          p,
          sup {
            text-align: left;
          }

          .title {
            margin-bottom: tokens.bpk-spacing-base();
            font-weight: bold;
          }

          .uppercase {
            letter-spacing: 0.2rem;
            text-transform: uppercase;
          }

          .interview-stage {
            margin-bottom: tokens.bpk-spacing-xxl();
          }

          .interview-stage-btn {
            margin-top: tokens.bpk-spacing-lg();
            margin-bottom: tokens.bpk-spacing-lg();
            padding: tokens.bpk-spacing-md() tokens.bpk-spacing-lg();
            border: none;
            border-radius: tokens.bpk-spacing-md();
            outline: 0;
            background-color: tokens.$bpk-color-sky-blue;
            color: tokens.$bpk-color-white;
            cursor: pointer;

            @include typography.bpk-body-default;

            @include breakpoints.bpk-breakpoint-above-mobile {
              margin-top: inherit;
              padding: tokens.bpk-spacing-base() tokens.bpk-spacing-lg();

              @include typography.bpk-subheading;
            }
          }

          .interview-note {
            text-align: left;

            @include typography.bpk-caption;

            @include breakpoints.bpk-breakpoint-above-mobile {
              @include typography.bpk-body-longform;
            }
          }
        }
      }

      .fade-in-delay-1 {
        @include mixins.fade-in-animation-delay(1);
      }

      .how-we-move-list {
        padding-left: 0; // This is due to auto padding on a list within a parent container
      }

      .how-we-move-list-item {
        margin-bottom: tokens.bpk-spacing-lg();
      }

      .fade-in-top-delay-1 {
        @include mixins.fade-in-top-animation-delay(1);
      }

      .fade-in-top-delay-2 {
        @include mixins.fade-in-top-animation-delay(2);
      }

      .fade-in-top-delay-225 {
        @include mixins.fade-in-top-animation-delay(2.25);
      }

      .fade-in-top-delay-25 {
        @include mixins.fade-in-top-animation-delay(2.5);
      }

      .fade-in-top-delay-275 {
        @include mixins.fade-in-top-animation-delay(2.75);
      }

      .fade-in-top-delay-3 {
        @include mixins.fade-in-top-animation-delay(3);
      }

      .fade-in-top-delay-325 {
        @include mixins.fade-in-top-animation-delay(3.25);
      }

      .fade-in-top-delay-35 {
        @include mixins.fade-in-top-animation-delay(3.5);
      }

      .fade-in-top-delay-375 {
        @include mixins.fade-in-top-animation-delay(3.75);
      }

      .fade-in-top-delay-4 {
        @include mixins.fade-in-top-animation-delay(4);
      }

      .fade-in-top-delay-425 {
        @include mixins.fade-in-top-animation-delay(4.25);
      }

      .fade-in-top-delay-45 {
        @include mixins.fade-in-top-animation-delay(4.5);
      }
    }

    .footer-icon {
      display: flex;
      justify-content: center;
      background-color: tokens.$bpk-color-sky-blue;

      img {
        display: inline-block;
        max-width: 38rem;
      }
    }
  }
}
