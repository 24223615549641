.JobsApp {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .early-careers-header-svg {
      float: right;
      display: none;
      max-width: 17rem;
      max-height: 17rem;
    }

    /* stylelint-disable-next-line order/order */
    @media (min-width: 32.0625rem) {
      .early-careers-header-svg {
        display: inherit;
      }
    }
  }
}
